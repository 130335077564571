@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~@mdi/font/scss/materialdesignicons";

:root {
  --accent-color: #258af4;
  --bg-white: #ffffff;
  --text-white: #ffffff;
  --success: #29b263;
  --error: #dc3545;
  --warning: #ff7e07;
}

html {
  background-color: rgb(13 110 253 / 2%);
}

body {
  // max-width: 1600px;
  // margin-inline: auto !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
  line-height: 1.2;
}

input[type="number"] {
  // -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

input,
select,
textarea {
  background-color: var(--bg-white);
  &:focus-visible {
    outline: 1px solid var(--accent-color) !important;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

button {
  & > span {
    vertical-align: middle;
  }
}

.react-select__control--is-focused {
  outline: 1px solid var(--accent-color) !important;
}

.react-select__input {
  outline: initial !important;
  &:focus-visible,
  & > input {
    outline: initial !important;
  }
}

.rdrMonth {
  width: 100% !important;
}

.Toastify__toast-theme--light.Toastify__toast--info,
.Toastify__toast-theme--light.Toastify__toast--warning,
.Toastify__toast-theme--light.Toastify__toast--success,
.Toastify__toast-theme--light.Toastify__toast--error {
  color: var(--text-white);
  svg {
    fill: #ffffff;
  }
}

.Toastify__toast-theme--light.Toastify__toast--success {
  background-color: var(--success);
}

.Toastify__toast-theme--light.Toastify__toast--error {
  background-color: var(--error);
}

.Toastify__toast-theme--light.Toastify__toast--info {
  background-color: #5a5a5a;
}

.Toastify__toast-theme--light.Toastify__toast--warning {
  background-color: #f45e25;
}

.uploadcare--widget__button_type_open,
.uploadcare--widget__button_type_open:focus {
  font-size: 0.9rem;
  line-height: 1;
  background-color: transparent;
  color: var(--accent-color);
  border: 1px solid var(--accent-color);
  cursor: pointer;
  &:hover {
    font-size: 0.9rem;
    line-height: 1;
    background-color: var(--accent-color);
    color: var(--text-white);
  }
}

.uploadcare--button_primary {
  background-color: transparent;
  color: var(--accent-color);
  &:hover {
    background-color: var(--accent-color);
    color: var(--text-white);
  }
}

::selection {
  background-color: #258af4;
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 0.5rem;
}
::-webkit-scrollbar-track {
  background-color: #d9d9d9;
}
::-webkit-scrollbar-thumb {
  background-color: #898989;
  border-radius: 2rem;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-color);
}
