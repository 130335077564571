.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  opacity: 0;
  animation: fadeIn 2s ease-in infinite;
  img {
    width: 7rem;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}
