.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: beige;
  padding: 2rem 0.2rem;
  padding-right: 0.7rem;
  margin-inline: auto;
  header,
  .body,
  table {
    width: 100%;
    th,
    td {
      text-align: left;
      text-transform: capitalize;
    }
  }
  header {
    padding-bottom: 1rem;
    border-bottom: 1px solid #000000;
    text-transform: uppercase;
    img {
      display: block;
      margin-inline: auto;
      margin-bottom: 1rem;
      width: 3rem;
    }
    h1 {
      font-size: 0.9rem;
      text-align: center;
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: 0.8rem;
      text-align: center;
      margin-bottom: 0.3rem;
    }
  }
  .body {
    padding: 1rem 0;
    border-bottom: 1px solid #000000;
    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.8rem;
      line-height: 1;
      margin-bottom: 0.2rem;
      text-transform: capitalize;
      span {
        font-weight: 500;
      }
    }
  }

  table {
    margin-top: 1rem;
    font-size: 0.7rem;
    border-collapse: collapse;
    th {
      font-weight: 500;
    }
    tr {
      th,
      td {
        padding: 0.1rem;
      }
    }
    tfoot {
      border-top: 1px solid #000000;
      border-bottom: 1px solid #000000;
      font-weight: 500;
    }
  }
}

@media print {
  .container {
    background: initial;
    margin: 0;
  }
}
