.container {
  display: flex;
  height: 100vh;
  & > a {
    position: fixed;
    top: 0;
    left: 0;
    margin: 2rem 5%;
    z-index: 3;
    img {
      width: 6rem;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    position: relative;
    padding-inline: 5%;
    width: 50%;
    height: 100vh;
    h1 {
      font-size: 1.8rem;
      margin-bottom: 0;
    }
    p {
      font-size: 1rem;
      margin-bottom: 0;
    }
    h6 {
      margin-bottom: 0;
    }
    fieldset {
      position: relative;
      display: flex;
      align-items: center;
      &:not(:last-child) {
      }
      label {
        width: 8rem;
      }
      input {
        height: 2.8rem;
        width: 100%;
        padding: 0.3rem 0.7rem;
        background-color: transparent;
        border: 1px solid #cccccc !important;
      }
      svg {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        height: 1rem;
        fill: #6c757d;
        cursor: pointer;
      }
    }
    p {
      span {
        color: var(--accent-color);
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    button {
      width: 100%;
    }
  }
  & > div {
    width: 50%;
    height: 100vh;
  }
}

@media screen and (max-width: 1045px) {
  .container {
    & > form {
      width: 100%;
      padding-inline: 20%;
    }
    & > div {
      display: none;
    }
  }
}

@media screen and (max-width: 720px) {
  .container {
    & > form {
      padding-inline: 5%;
      p {
        font-size: 0.9rem;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    & > form {
      fieldset {
        label {
          // width: 17rem;
          font-size: 0.9rem;
        }
      }
    }
  }
}
