.container {
  display: flex;
  height: 100vh;
  & > a {
    position: fixed;
    top: 0;
    left: 0;
    margin: 2rem 5%;
    z-index: 3;
    img {
      width: 6rem;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-inline: 5%;
    width: 50%;
    height: 100vh;
    h1 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1rem;
    }
    fieldset {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      label {
        width: 13rem;
      }
      input {
        height: 2.8rem;
        width: 100%;
        padding: 0.3rem 0.7rem;
        background-color: transparent;
        border: 1px solid #cccccc !important;
      }
    }
    p {
      span {
        color: var(--accent-color);
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    button {
      margin: 1rem 0;
      width: 100%;
    }
  }
  & > div {
    width: 50%;
    height: 100vh;
  }
}

@media screen and (max-width: 1045px) {
  .container {
    & > form {
      width: 100%;
      padding-inline: 20%;
    }
    & > div {
      display: none;
    }
  }
}

@media screen and (max-width: 780px) {
  .container {
    & > form {
      fieldset {
        label {
          width: 15rem;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    & > form {
      padding-inline: 5%;
      fieldset {
        label {
          width: 20rem;
        }
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
}
