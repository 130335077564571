.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  justify-content: center;
  text-align: center;
  width: 70vh;
  min-width: 20rem;
  height: 20rem;
  background-color: #eeeeee;
  color: #414141;
  padding: 2rem;
  border-radius: 2rem;
  h1 {
    font-size: 4rem;
  }
  h3 {
    font-size: 1.7rem;
  }
  a {
    color: var(--accent-color);
  }
}

@media screen and (max-width: 290px) {
  .container {
    h1 {
      font-size: 4rem;
    }
    h3 {
      font-size: 1.7rem;
    }
    a {
      color: var(--accent-color);
    }
  }
}
