.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1150;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  max-width: 35rem;
  background-color: #ffffff;
  z-index: 1150;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem 1rem;
    border-bottom: 1px solid #cccccc;
    h1 {
      font-size: 1.2rem;
      margin: 0;
      text-transform: capitalize;
    }
    svg {
      height: 1.4rem;
      fill: #5d5d5d;
      cursor: pointer;
    }
  }
  form {
    position: relative;
    .body {
      height: 80vh;
      max-height: 30rem;
      overflow-y: auto;
      padding: 1.3rem 1rem;
      width: 100%;
      .form-group,
      fieldset {
        display: flex;
        flex-direction: column;
        position: relative;
        label {
          display: flex;
          align-items: center;
          font-size: 0.8rem;
          margin-bottom: 0.4rem;
          span {
            color: #ff0000;
            margin-left: 0.2rem;
          }
        }
        img {
          width: 6rem;
          height: 6rem;
          object-fit: cover;
          margin-top: 1rem;
        }
        & > input,
        & > select {
          font-size: 0.8rem;
          padding: 2px 8px;
          border: 1px solid #cccccc !important;
          height: 29px;
          width: 100%;
          &[type="text"] {
            text-transform: capitalize;
          }
          &:disabled {
            background-color: #f5f5f5;
            cursor: not-allowed;
          }
        }
        .sub-group {
          display: flex;
          flex-direction: column;
          width: 48%;
          & > label {
            text-transform: capitalize;
          }
          & > input,
          & > select {
            font-size: 0.8rem;
            padding: 2px 8px;
            border: 1px solid #cccccc !important;
            height: 29px;
            width: 100%;
            &[type="text"] {
              text-transform: capitalize;
            }
            &:disabled {
              background-color: #f5f5f5;
              cursor: not-allowed;
            }
          }
        }
        .upload-image {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2rem;
          width: 12rem;
          border: 1px solid #cccccc;
          padding: 0.3rem 0.5rem;
          cursor: pointer;
          svg {
            width: 1rem;
            margin-right: 0.4rem;
          }
          span {
            color: #212529;
          }
        }
        .image-container {
          position: relative;
          img {
            width: 12rem;
            height: 12rem;
            object-fit: cover;
          }
          svg {
            position: absolute;
            top: 0;
            left: 12.5rem;
            // margin: 0.4rem;
            width: 1.2rem;
            cursor: pointer;
          }
        }
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
        .variants {
          position: relative;
          padding: 0;
          margin-bottom: 0;
          li {
            display: flex;
            align-items: center;
            gap: 0.6rem;
            background-color: rgb(13 110 253 / 15%);
            padding: 0.5rem;
            margin-top: 0.5rem;
            font-size: 0.8rem;
            &:first-child {
              margin-top: 1rem;
            }
            span {
              text-align: center;
              text-transform: capitalize;
              b {
                font-weight: 500;
              }
            }
          }
          i {
            margin-left: auto;
            line-height: 1;
            font-size: 1rem;
            cursor: pointer;
            &:last-child {
              color: rgb(237, 64, 20);
            }
          }
        }
      }
      .read-only {
        & > label {
          font-weight: 500;
          color: var(--accent-color);
        }
        & > input {
          position: relative;
          border: none !important;
          border-bottom: 1px solid #cccccc !important;
          &:disabled {
            color: #000000;
            background-color: initial !important;
            cursor: default !important;
          }
        }
        .sub-group {
          & > label {
            font-weight: 500;
            color: var(--accent-color);
          }
          & > input {
            position: relative;
            border: none !important;
            border-bottom: 1px solid #cccccc !important;
            &:disabled {
              color: #000000;
              background-color: initial !important;
              cursor: default !important;
            }
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    gap: 1rem;
    padding: 0.8rem;
    border-top: 1px solid #cccccc;
    & > button {
      background-color: var(--accent-color);
      border: 1px solid var(--accent-color);
      color: #ffffff;
      transition: all 0.4s;
      font-size: 0.9rem;
      padding: 0.1rem 0.5rem;
      width: 4rem;
      cursor: pointer;
      &:nth-child(1) {
        margin-left: auto;
        border-color: var(--error);
        background-color: var(--error);
      }
      // span {
      //   margin: auto;
      //   margin-right: 0.3rem;
      //   vertical-align: middle;
      //   transition: all 0.4s;
      // }
    }
    .cancel {
      background-color: transparent !important;
      border-color: #cccccc !important;
      color: #000000 !important;
    }
  }
}
