.container {
  .subscription-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--error);
    color: var(--text-white);
    height: 2rem;
    text-align: center;
    padding-inline: 1rem;
    line-height: 1;
    p {
      margin: 0;
      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  nav {
    display: flex;
    align-items: center;
    gap: 2vw;
    height: 6.5rem;
    // margin-left: 15rem;
    padding-inline: 2rem;
    background-color: #ffffff;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    z-index: 1030;
    & > h1 {
      color: var(--accent-color);
      font-size: 1.4rem;
      text-transform: capitalize;
      margin: 0;
    }
    & > div {
      & > h4 {
        text-align: right;
      }
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      margin: 0;
      margin-left: auto;
      text-transform: capitalize;
      line-height: 1;
      font-size: 1.3rem;
      & > span {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.3rem;
        align-self: flex-end;
        position: relative;
        font-size: 0.8rem;
        font-weight: 400;
        margin-top: 0 !important;
        background-color: var(--accent-color);
        color: var(--text-white);
        padding: 0.2rem 0.4rem;
        border-radius: 0.7rem;
        line-height: 1;
        & > svg {
          stroke-width: 2;
          cursor: pointer;
        }
        & > div {
          position: absolute;
          top: 2rem;
          right: 0;
          width: 200px;
          z-index: 10;
          background-color: var(--bg-white);
          padding: 1rem;
          box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.075);
          border-radius: 0.5rem;
          border: 1px solid #ccc;
        }
        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -0.7rem;
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;
          background-color: lime;
          content: "";
        }
      }
    }
    .svg-container {
      border: 1px solid #ccc;
      padding: 0.2rem;
      cursor: pointer;
    }
    & > svg {
      // margin: 2rem;
      cursor: pointer;
    }
    .dropdown {
      position: absolute;
      top: 6rem;
      right: 2rem;
      width: 17rem;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      flex-direction: column;
      justify-content: space-evenly;
      gap: 0;
      background: #ffffff;
      font-size: 1rem;
      z-index: 1030;
      ul {
        max-height: 30rem;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        overflow-y: auto;
      }
      a,
      button {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        color: #258af4;
        width: 100%;
        padding: 0.4rem 1.2rem;
        text-decoration: none;
        text-transform: capitalize;
        transition: all 0.3s;
        &:hover {
          background-color: #eef6ff;
        }
        i {
          margin-right: 0.3rem;
          line-height: 1;
        }
      }
      button {
        background-color: var(--error);
        color: var(--text-white);
        &:hover {
          background-color: var(--error);
        }
      }
      .active {
        background-color: #eef6ff;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .subscription-banner {
      p {
        font-size: 0.8rem;
        margin: 0;
      }
    }
    & > a {
      & > img {
        height: 2rem;
      }
    }

    & > div {
      font-size: 1.2rem;
    }
    nav {
      padding-inline: 1rem;
      & > h1 {
        font-size: 1.2rem;
      }
      & > div {
        font-size: 1.1rem;
        span {
          font-size: 0.7rem;
        }
      }
    }
  }
}
