.container {
  display: flex;
  height: 100vh;
  & > a {
    position: fixed;
    top: 0;
    left: 0;
    margin: 2rem 5%;
    z-index: 3;
    img {
      width: 6rem;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline: 5%;
    width: 50%;
    height: 100vh;
    h1 {
      font-size: 1.8rem;
      margin-bottom: 0.8rem;
      line-height: 1.4;
    }
    fieldset {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 1.2rem;
      }
      label {
        width: 15rem;
        span {
          color: red;
        }
      }
      & > input,
      * > select {
        height: 2.5rem;
        width: 100%;
        padding: 0.3rem 0.7rem;
        background-color: transparent;
        border: 1px solid #cccccc !important;
        &:not([type="email"]) {
          text-transform: capitalize;
        }
        &:focus-visible {
          outline: 1px solid var(--accent-color) !important;
        }
      }
    }

    button {
      width: 100%;
    }
  }
  & > div {
    width: 50%;
    height: 100vh;
  }
}

@media screen and (max-width: 1045px) {
  .container {
    & > form {
      width: 100%;
      padding-inline: 20%;
    }
    & > div {
      display: none;
    }
  }
}

@media screen and (max-width: 720px) {
  .container {
    & > form {
      padding-inline: 5%;
      p {
        font-size: 0.9rem;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    & > form {
      fieldset {
        label {
          width: 17rem;
          font-size: 0.9rem;
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .container {
    & > form {
      padding-inline: 3%;
    }
  }
}
