.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  svg {
    width: 3rem;
    stroke-width: 1.5;
    stroke: var(--accent-color);
  }
  p {
    font-size: 1.3rem;
    line-height: 1.5;
    text-align: center;
    width: 16rem;
  }
}
