.container {
  padding: 1rem;
  width: 100%;
  .daily-info,
  .monthly-info {
    width: 100%;
    padding: 3rem 1rem;
    box-shadow: 4px 6px 10px rgb(0 0 0 / 10%);
    border-radius: 0.3rem;
    & > h1 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
    .cards {
      display: flex;
      justify-content: space-between;
      & > div {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-inline: 1rem;
        width: 23%;
        height: 7rem;
        // background-color: #91abff !important;
        color: var(--text-white);
        h1 {
          margin: 0;
          font-size: 2.5rem;
          // margin-top: 1.5rem;
        }
        p {
          margin: 0;
        }
        span {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          justify-self: flex-end;
          padding-inline: 2rem;
          background-color: rgb(0, 0, 0, 0.1);
        }
      }
    }
    canvas {
      margin-top: 4rem;
      width: 100% !important;
    }
  }
  .monthly-info {
    margin-top: 5rem;
  }
  .tables {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    & > div {
      width: 30%;
      height: 15rem;
    }
    .inventory-summary {
      box-shadow: 4px 6px 10px rgb(0 0 0 / 10%);
      border-radius: 0.3rem;
      padding: 1rem;
      h1 {
        font-size: 1.3rem;
        margin: 0;
        border-bottom: 1px solid #cccccc;
        padding-bottom: 1rem;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: flex;
          justify-content: space-between;
          padding: 1rem 0;
          font-size: 1rem;
          &:not(:last-child) {
            border-bottom: 1px solid #cccccc;
          }
          p {
            font-size: 1rem;
            margin: 0;
          }
          span {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 970px) {
  .container {
    .daily-info,
    .monthly-info {
      .cards {
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr 1fr;
        & > div {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .container {
    padding: 0;
    .daily-info,
    .monthly-info {
      .cards {
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr;
        & > div {
          width: 100%;
        }
      }
    }
  }
}
