.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 40%;
  max-width: 35rem;
  min-width: 30rem;
  background-color: #ffffff;
  z-index: 1201;
  & > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem 1rem;
    border-bottom: 1px solid #cccccc;
    h1 {
      font-size: 1.2rem;
      margin: 0;
      text-transform: capitalize;
    }
    svg {
      height: 1.4rem;
      fill: #5d5d5d;
      cursor: pointer;
    }
  }
  .body {
    & > div {
      padding: 1rem;
    }
    .variants {
      padding-bottom: 0;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.7rem;
        font-size: 0.9rem;
        cursor: pointer;
      }
      .header {
        h1 {
          font-size: 1rem;
        }
        button {
          width: 8rem;
          background-color: #258af4;
          border: 1px solid #258af4;
          color: #ffffff;
        }
      }
      .main {
        max-height: 20rem;
        overflow-y: auto;
        padding: 0.5rem 0;
        margin: 0;
        li {
          display: flex;
          flex-direction: column;
          padding: 1rem;
          transition: all 0.3s;
          cursor: pointer;
          &:hover {
            background-color: #eeeeee;
          }
          &:not(:last-child) {
            border-bottom: 1px solid #ccc;
          }
          h2 {
            display: flex;
            font-size: 0.9rem;
            text-transform: capitalize;
            margin-bottom: 0.5rem;
            span {
              margin-left: auto;
            }
          }
          button {
            width: 5rem;
            background-color: transparent;
            border: 1px solid #bbbbbb;
          }
        }
        .selected {
          button {
            background-color: #258af4;
            border: 1px solid #258af4;
            color: #ffffff;
          }
        }
      }
    }
    .checkout {
      position: relative;
      .header {
        h1 {
          font-size: 1rem;
          font-weight: 500;
          text-align: center;
          margin: 0;
          line-height: 1.7;
          i {
            margin-left: 0.3rem;
          }
        }
      }
      & > .options {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        width: 80%;
        min-width: 20rem;
        margin: 1.2rem auto;
        max-height: 200px;
        overflow-y: auto;
        padding-inline: .8rem;
        & > button {
          display: flex;
          align-items: center;
          height: 2rem;
          width: 100%;
          border: 1px solid #cccccc;
          padding-inline: 4px;
          font-size: 0.8rem;
          transition: all 0.3s;
          text-transform: capitalize;
          overflow: hidden;
          white-space: nowrap;
          cursor: pointer;
          &:hover {
            border: 1px solid var(--accent-color);
          }
          &:nth-child(odd) {
            &:last-child {
              width: 100%;
            }
          }
          svg {
            width: 1.3rem;
            height: 1.1rem;
            margin-right: 3px;
            stroke-width: 1.5;
          }
        }
        .selected {
          border: 1px dashed var(--accent-color);
        }
        & > div {
          display: flex;
          align-items: flex-end;
          // justify-content: end;
          input {
            margin-left: 0.5rem;
          }
          label {
            font-size: 0.8rem;
            line-height: 1;
          }
        }
      }
      .cash-dialog,
      .card-dialog,
      .bank-transfer-dialog,
      .points-dialog,
      .coupon-dialog,
      .split-payment-dialog,
      .continue-split-payment-dialog,
      .discount-dialog,
      .print-receipt,
      .pending-sale {
        position: absolute;
        left: 0;
        top: 1px;
        background-color: #ffffff;
        height: 100%;
      }
      .cash-dialog,
      .card-dialog,
      .bank-transfer-dialog,
      .discount-dialog,
      .points-dialog,
      .continue-split-payment-dialog,
      .coupon-dialog,
      .print-receipt,
      .pending-sale {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        width: 100%;
        p {
          margin: 0;
          b {
            font-weight: 500;
          }
        }
        .buttons {
          display: flex;
          justify-content: center;
          gap: 1rem;
          button {
            font-size: 0.9rem;
            text-transform: capitalize;
            padding: 0.1rem 0.5rem;
            width: 5rem;
            cursor: pointer;
            &:nth-child(1) {
              border: 1px solid #cccccc;
              background-color: transparent;
            }
            &:nth-child(2) {
              background-color: #258af4;
              border: 1px solid #258af4;
              color: #ffffff;
            }
          }
        }
      }
      .cash-dialog {
        padding: 1rem;
        .input {
          position: relative;
          width: 80%;
          min-width: 20rem;
          margin-inline: auto;
          .currency {
            color: unset;
            left: 0;
            right: unset;
            font-size: unset;
            cursor: unset;
          }
          input {
            display: block;
            width: 100%;
            padding: 0.2rem 1.4rem;
            border: 1px solid #ccc !important;
          }
          span {
            position: absolute;
            top: 50%;
            right: 0;
            margin-inline: 0.6rem;
            transform: translateY(-50%);
            text-transform: uppercase;
            font-weight: 500;
            font-size: 0.8rem;
            color: var(--accent-color);
            cursor: pointer;
          }
        }
      }
      .split-payment-dialog {
        padding: 2rem;
        width: 100%;
        h1 {
          font-size: 1rem;
        }
        .options {
          .form-group {
            display: flex;
            align-items: center;
            margin: 0;
            margin-bottom: 0.3rem;
            input {
              margin-right: 0.5rem;
            }
            label {
              margin: 0;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: center;
          gap: 1rem;
          // margin-top: 1rem;
          button {
            font-size: 0.9rem;
            text-transform: capitalize;
            padding: 0.2rem 0.5rem;
            &:nth-child(1) {
              border: 1px solid #cccccc;
              background-color: transparent;
            }
            &:nth-child(2) {
              background-color: #258af4;
              border: 1px solid #258af4;
              color: #ffffff;
            }
          }
        }
      }
      .coupon-dialog {
        padding: 1rem;
        gap: 1rem;
        z-index: 1;
        input {
          width: 80%;
          min-width: 20rem;
          margin-inline: auto;
          display: block;
          padding: 0.2rem 1.4rem;
          border: 1px solid #ccc !important;
        }
      }
      .continue-split-payment-dialog {
        align-items: unset;
        padding: 1rem;
        .options {
          display: grid;
          gap: 1rem;
          & > fieldset {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            margin-bottom: 0.7rem;
            label {
              font-size: 0.9rem;
              line-height: 1;
              margin: 0;
              width: 7rem;
              text-transform: capitalize;
            }
            & > div {
              position: relative;
              input {
                width: 100%;
                height: 2rem;
                font-size: 0.9rem;
                padding: 0.2rem 0.7rem;
                border: 1px solid #ccc !important;
              }
              svg {
                position: absolute;
                right: 0.5rem;
                top: 50%;
                transform: translateY(-50%);
                width: 1rem;
                fill: #ccc;
                cursor: pointer;
              }
            }
          }
        }
        .buttons {
          grid-row: 2;
          grid-column: 1/-1;
        }
      }
      .discount-dialog {
        z-index: 1;
        h1 {
          font-size: 1.1rem;
          margin-bottom: 1rem;
        }
        button {
          width: 3rem !important;
        }
      }
    }
    .saved-orders,
    .offline-sales {
      height: 25rem;
      overflow-y: auto;
      & > h1 {
        font-size: 0.7rem;
        color: var(--error);
        text-align: center;
        margin-bottom: 0.5rem;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          position: relative;
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
          .title {
            display: flex;
            align-items: center;
            padding: 0.7rem 1rem;
            background-color: #2589f421;
            gap: 0.5rem;
            cursor: pointer;
            h1 {
              font-size: 0.8rem;
              margin: 0;
              text-transform: capitalize;
              span {
                text-transform: initial;
              }
            }
            & > span {
              font-size: 0.7rem;
              line-height: 1;
              color: #258af4;
              white-space: nowrap;
              cursor: pointer;
            }
          }
          table {
            margin-top: 0.5rem;
            font-size: 0.8rem;
            border-collapse: collapse;
            width: 100%;
            opacity: 1;
            transition: all 0.4s;
            tfoot {
              background-color: #2589f45d;
              font-weight: 500;
            }
            th,
            td {
              border: 1px solid #dddddd;
              padding: 0 1rem;
              text-transform: capitalize;
              &:not(:first-child) {
                text-align: right;
              }
            }
          }
        }
        .selected {
          position: relative;
          transition: all 0.4s;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -3px;
            width: 0.2rem;
            background-color: #258af4;
            height: 100%;
          }
        }
      }
    }
    .clear-cart {
      p {
        text-align: center;
        margin-bottom: 1rem;
      }
      .buttons {
        display: flex;
        justify-content: center;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 1.7rem;
          width: 4rem;
          font-size: 0.9rem;
          cursor: pointer;
          &:first-child {
            margin-right: 1rem;
            border: 1px solid #cccccc;
            background-color: transparent;
          }
          &:last-child {
            background-color: #258af4;
            border: 1px solid #258af4;
            color: #ffffff;
          }
        }
      }
    }
    .form-group {
      margin: 2rem;
      & > div {
        position: relative;
        font-size: 0.9rem;

        & > input {
          width: 100%;
          height: 38px;
          padding: 0.3rem 1.9rem;
          border: 1px solid #cccccc !important;
        }
        & > svg {
          position: absolute;
          top: 50%;
          left: 0.6rem;
          transform: translateY(-50%);
          width: 1rem;
          stroke: #cccccc;
          path {
            &:first-child {
              fill: #cccccc;
            }
          }
        }
        & > span {
          position: absolute;
          right: 0.6rem;
          top: 50%;
          transform: translateY(-50%);
          color: #cccccc;
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    padding: 0.8rem;
    border-top: 2px solid #cccccc;
    .form-group {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin: 0;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    .discount-options {
      width: 100%;
      & > h1 {
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
      }
      .form-group {
        margin: 0px;
        display: flex;
        align-items: center;
        input[type="number"] {
          margin-left: 0.4rem;
          padding-inline: 0.5rem;
          width: 5rem;
          border: 1px solid #cccccc !important;
        }
      }
    }
    label {
      margin: 0;
      font-size: 0.9rem;
      // line-height: 1;
    }
    button,
    span {
      font-size: 0.9rem;
      text-transform: capitalize;
      padding: 0.2rem 0.5rem;
      cursor: pointer;
    }
    span {
      margin-left: auto;
      margin-right: 1rem;
      border: 1px solid #cccccc;
      background-color: transparent;
    }
    button {
      background-color: #258af4;
      border: 1px solid #258af4;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 540px) {
  .container {
    padding: 1rem;
    header {
      & > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }
    }
    .form-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      margin-top: 0.5rem;
      & > div {
        width: 100%;
      }
    }
  }
}
