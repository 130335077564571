.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: none;
  outline: 1px solid var(--accent-color);
  height: 2.4rem;
  width: 12rem;
  font-weight: 500;
  font-size: 0.9rem;
  color: var(--text-white);
  line-height: 1;
  background-color: var(--accent-color);
  transition: all 0.3s;
  box-shadow: 1px 4px 4px rgb(0 0 0 / 15%);
  cursor: pointer;
  &:hover {
    background-color: #006ee0;
  }
  &:disabled {
    background-color: #999999;
    outline: 1px solid #6c757d;
  }
  & > span {
    vertical-align: middle;
  }
}
