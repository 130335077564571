@mixin table {
  padding: 1rem;
  padding-top: 0;
  margin-top: 2rem;
  background-color: #f3f3f3;
  overflow-x: auto;
  border-radius: 1rem;
  table {
    font-size: 0.9rem;
    border-collapse: collapse;
    width: 100%;
    opacity: 1;
    transition: all 0.4s;
    tr {
      th,
      td {
        padding: 0.7rem 0.6rem;
        white-space: nowrap;
      }
    }
    thead {
      tr {
        // background-color: #f3f3f3;
        // border-bottom: 10px solid #eeeeee;
        // &:not(:last-child) {
        // }
        th {
          color: var(--accent-color);
          text-align: left;
          white-space: nowrap;
          & > input,
          & > select {
            display: block;
            width: 100%;
            height: 2rem;
            padding: 0.3rem 0.5rem;
            font-size: 0.8rem;
            font-weight: 400;
            color: #666666;
          }
          & > form {
            display: flex;
            input {
              font-weight: 400;
              // margin-left: auto;
              color: #666666;
              padding: 2px 8px;
              height: 2.5rem;
              width: 15rem;
              background-color: #f3f3f3;
            }
          }
          svg {
            display: inline-block;
            margin-left: 0.2rem;
          }
        }
      }
    }
    tbody {
      tr {
        transition: all 0.3s;
        background-color: var(--bg-white);
        &:not(:last-child) {
          border-bottom: 10px solid #eeeeee;
        }
        &:hover {
          // background-color: #f3f3f3;
          // outline: 1px solid var(--accent-color);
          cursor: pointer;
        }
        td {
          text-transform: capitalize;
          span {
            text-transform: initial;
          }
          img {
            width: 2rem;
            height: 2rem;
            object-fit: cover;
          }
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
              &:not(:last-child) {
                margin-bottom: 0.2rem;
              }
            }
          }
          button {
            width: 10rem;
            // margin-inline: auto;
            &:disabled {
              background-color: var(--success);
              outline: 1px solid #6c757d;
            }
          }
        }
        th {
          color: var(--accent-color);
          width: 0;
        }
        .status-pending,
        .status-complete {
          font-weight: bold;
        }
        .status-pending {
          color: var(--warning);
        }
        .status-complete {
          color: var(--success);
        }
      }
    }
  }
}

@mixin pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 0.7rem;
  margin: 2rem 0;
  button {
    border: none;
    padding: 0;
    background-color: transparent;
    svg {
      display: block;
      height: 1.3rem;
      fill: var(--accent-color);
    }
  }
  fieldset {
    input,
    select {
      margin-left: 1rem;
      padding: 2px 8px;
      height: 2rem;
      width: 3rem;
      background-color: var(--bg-secondary);
      border: 1px solid #cccccc;
    }
  }
}
