.container {
  padding: 1rem;
  h1,
  h2,
  h3,
  p {
    margin: 0;
  }
  .controls {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin: 1rem 0;
    .form-group {
      position: relative;
      display: inline-block;
      flex-shrink: 0;
      width: 12rem;
      & > input,
      & > select {
        border: 1px solid #cccccc !important;
        width: 100%;
        height: 2.5rem;
        padding: 0.3rem 0.8rem;
        font-size: 0.9rem;
      }
      & > p {
        position: absolute;
        top: 50%;
        left: 0.8rem;
        transform: translateY(-50%);
        font-size: 0.9rem;
      }
      & > svg {
        position: absolute;
        top: 50%;
        right: 0.8rem;
        transform: translateY(-50%);
        fill: #bbbbbb;
        width: 1.1rem;
        cursor: pointer;
      }
      .calendar {
        position: absolute;
        box-shadow: 3px 5px 10px rgb(0 0 0 / 25%);
        left: 0;
        top: 3rem;
        z-index: 1;
      }
    }
    a {
      text-decoration: none;
      background-color: initial;
      color: var(--accent-color);
      width: 12rem;
    }
  }
  & > h6 {
    &:not(:last-child) {
      margin-bottom: 0.6rem;
    }
  }
  .table {
    width: 100%;
    margin-top: 2rem;
    // padding: 0 4rem;
    .row {
      &:not(:last-child) {
        border-bottom: 1px solid #cccccc;
      }
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
        height: 5rem;
        padding-inline: 1.6rem;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          background-color: #eeeeee;
        }
        // & > div {
        //   width: 20%;
        // }
        .thumbnail {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 0.4rem;
          background-color: #258af4;
          color: #ffffff;
          font-size: 0.5rem;
          font-weight: 500;
          margin-right: 0.4rem;
          text-transform: uppercase;
          // border: 1px solid #cccccc;
        }
        .date {
          width: 20%;
          min-width: 11rem;
          span {
            display: flex;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
        .customer {
          display: flex;
          align-items: center;
          width: 20%;
          min-width: 4rem;
          & > div {
            text-transform: capitalize;
            h3 {
              font-size: 1rem;
            }
          }
        }
        .cashier {
          display: flex;
          align-items: center;
          width: 20%;
          min-width: 9rem;
          & > div {
            text-transform: capitalize;
            h3 {
              font-size: 1rem;
            }
          }
        }
        .amount {
          width: 10%;
          min-width: 3rem;
          p {
            text-align: center;
            span {
              font-weight: 500;
            }
          }
        }
        .status {
          width: 10%;
          min-width: 6rem;
          text-transform: capitalize;
        }
      }
      .dropdown {
        padding: 1.6rem;
        & > h1 {
          position: relative;
          padding-bottom: 0.5rem;
          margin-bottom: 1rem;
          border-bottom: 1px solid #cccccc;
          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 3.5rem;
            background-color: var(--accent-color);
            height: 2px;
          }
        }
        .payment-methods {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          gap: 0 1rem;
          margin-bottom: 0.5rem;
          span {
            text-transform: capitalize;
            white-space: nowrap;
            font-size: 0.9rem;
            color: var(--accent-color);
          }
        }
        .details {
          display: flex;
          flex-direction: column;
          width: 70%;
          .items {
            margin: 0;
            padding: 0;
            li {
              display: flex;
              align-items: center;
              column-gap: 1rem;
              padding: 0.3rem 0;
              &:not(:last-child) {
                border-bottom: 1px solid #cccccc;
              }
              h2 {
                font-size: 0.8rem;
                width: 5%;
                min-width: 1rem;
                text-transform: capitalize;
                &:nth-child(2),
                &:nth-child(4),
                &:last-child {
                  width: 20%;
                }
              }
              p {
                font-size: 0.8rem;
                width: 5rem;
              }
            }
          }
        }
        .actions {
          & > div {
            display: flex;
            align-items: center;
            column-gap: 0.4rem;
            transition: all 0.3s;
            cursor: pointer;
            &:hover {
              h2 {
                color: var(--accent-color);
              }
              svg {
                fill: var(--accent-color);
                stroke: var(--accent-color);
              }
              // &:last-child {
              //   svg {
              //     fill: none;
              //   }
              // }
            }
            p {
              font-size: 0.9rem;
            }
            h2 {
              font-size: 0.9rem;
              line-height: 1;
            }
            svg {
              width: 1rem;
              height: 1rem;
            }
            &:not(:last-child) {
              margin-bottom: 0.7rem;
            }
          }
        }
      }
    }
    .selected {
      position: relative;
      border-bottom: none !important;
      margin-bottom: 1rem;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      transition: all 0.4s;
      & > div {
        &:nth-child(1) {
          background-color: #eeeeee;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0.3rem;
        background-color: #258af4;
        height: 100%;
      }
    }
  }
}

.modal {
  min-width: 20rem;
  form {
    fieldset {
      width: 80%;
      margin: 2rem auto;
      & > input {
        padding-inline: 8px;
        border: 1px solid rgb(204, 204, 204);
        height: 38px;
        width: 100%;
      }
      .product-quantity {
        display: grid;
        column-gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
        & > div {
          margin-top: 1rem;
          label {
            font-size: 0.8rem;
            text-transform: capitalize;
          }
          input {
            padding-inline: 8px;
            border: 1px solid rgb(204, 204, 204);
            height: 38px;
            width: 100%;
          }
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      padding: 0.8rem;
      border-top: 1px solid #cccccc;
      button,
      span {
        font-size: 0.9rem;
        text-transform: capitalize;
        padding: 0.2rem 0.5rem;
        cursor: pointer;
      }
      span {
        margin-left: auto;
        margin-right: 1rem;
        border: 1px solid #cccccc;
        background-color: transparent;
      }
      button {
        background-color: #258af4;
        border: 1px solid #258af4;
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 940px) {
  .container {
    .table {
      .row {
        .header {
          padding-inline: 0.9rem;
          .cashier,
          .customer {
            & > div {
              h3 {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .container {
    .table {
      .row {
        .header {
          font-size: 0.8rem;
          padding-inline: 0.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .container {
    .controls {
      & > h6 {
        font-size: 0.9rem;
      }
    }
    .table {
      .row {
        .header {
          .cashier {
            min-width: 5rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    .controls {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .form-group {
        width: 100%;
        .calendar {
          flex-direction: column;
          & > div {
            &:first-child {
              width: 100%;
            }
            .rdrMonth {
              width: 100%;
            }
          }
        }
      }
      a {
        width: 100%;
      }
    }
    .table {
      .row {
        .dropdown {
          padding: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .container {
    .table {
      .row {
        .dropdown {
          .details {
            width: 100%;
          }
          .actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 0.5rem 1.5rem;
            margin-bottom: 1rem;
            & > div {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    .controls {
      grid-template-columns: repeat(2, 1fr);
      .form-group {
        width: 100%;
        .calendar {
          left: initial;
          right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    .table {
      .row {
        .header {
          font-size: 0.8rem;
          .date,
          .cashier,
          .status {
            font-size: 0.8rem;
            min-width: 4rem;
          }
        }
      }
      .selected {
        &::before {
          width: 0.1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .container {
    .controls {
      .form-group {
        input,
        select {
          padding: 0.3rem 0.8rem !important;
        }
        input[type="date"] {
          margin-top: 1rem;
        }
        p {
          position: absolute;
          top: 0;
        }
      }
    }
    .table {
      .row {
        .dropdown {
          padding: 1rem;
          .payment-methods {
            span {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .container {
    padding: 0.4rem;
  }
}
