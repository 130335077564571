@import "./mixins.module.scss";

.container {
  padding: 2rem;
  max-width: 1200px;
  margin-inline: auto;
  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin: 2rem 0;
    .form-group {
      position: relative;
      select {
        text-transform: capitalize;
      }
      input,
      select {
        border: 1px solid #cccccc !important;
        width: 10rem;
        height: 2.5rem;
        padding: 0.3rem 0.8rem;
        font-size: 0.9rem;
      }
      svg {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        fill: #bbbbbb;
        margin-right: 0.4rem;
        width: 1.1rem;
        cursor: pointer;
      }
    }
    a {
      text-decoration: none;
      background-color: initial;
      color: var(--accent-color);
    }
    a,
    button {
      width: 10rem;
      & > svg {
        width: 1.1rem;
        margin-right: 0.4rem;
      }
      .rotate {
        animation: rotation 2s infinite linear;
      }
    }
  }
  & > h5 {
    margin-top: 2rem;
    max-width: 1200px;
    margin-inline: auto;
  }
  & > h6 {
    font-size: 1rem;
    span {
      white-space: nowrap;
    }
  }
  .filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  .table {
    @include table();
  }
  .pagination {
    @include pagination();
  }
}

// warehouse transfer
.modal {
  form {
    .form-group {
      margin: 2rem;
      padding: 1.2rem;
      input {
        font-size: 0.8rem;
        padding: 2px 8px;
        border: 1px solid #cccccc !important;
        height: 2.5rem;
        width: 100%;
      }
    }
    .footer {
      display: flex;
      align-items: center;
      padding: 0.8rem;
      border-top: 1px solid #cccccc;
      button,
      & > span {
        font-size: 0.9rem;
        text-transform: capitalize;
        padding: 0.2rem 0.5rem;
        cursor: pointer;
      }
      & > span {
        margin-left: auto;
        margin-right: 1rem;
        border: 1px solid #cccccc;
        background-color: transparent;
      }
      button {
        background-color: #258af4;
        border: 1px solid #258af4;
        color: #ffffff;
        width: 3rem;
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media screen and (max-width: 930px) {
  .container {
    .buttons {
      // display: grid;
      // gap: 1rem;
      // grid-template-columns: repeat(2, 1fr);
      // .form-group {
      //   input,
      //   select {
      //     width: 100%;
      //     margin: 0;
      //   }
      // }
      // a {
      //   margin: 0;
      //   button {
      //     width: 100%;
      //   }
      // }
      // button {
      //   width: 100%;
      // }
    }
  }
}

@media screen and (max-width: 540px) {
  .container {
    padding: 1rem;
    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      a,
      input,
      select,
      button {
        width: 100% !important;
      }
    }
  }
}
