.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin: 2rem 0;
  fieldset {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    label {
    }
    p {
      margin: 0;
    }
    input,
    select {
      width: 3rem;
      height: 2rem;
      padding-inline: 0.5rem;
      border: 1px solid #cccccc !important;
    }
    svg {
      height: 1.3rem;
      fill: var(--accent-color);
      cursor: pointer;
    }
    button {
      color: var(--text-white);
      background-color: var(--accent-color);
      border: 1px solid var(--accent-color);
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border: 1px solid #cccccc;
    border-radius: 10px;
    background-color: #ffffff;
    color: #2c3e50;
    cursor: pointer;
  }
}
