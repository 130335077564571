.container {
  position: fixed;
  z-index: 1040;
  left: 0;
  top: 0;
  width: 100%;
  background-color: var(--bg-white);
  overflow-y: auto;
  height: 100%;
  padding: 3rem;
  .nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1241;
  }
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    width: 100%;
    h1 {
      font-size: 1.5rem;
    }
    & > div {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      button {
        &:not(:first-child) {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.8rem;
          border: none;
          padding: 0;
          color: #444444;
          margin-left: 1.3rem;
          background-color: transparent;
          &:hover {
            text-decoration: underline;
          }
          svg {
            width: 1.1rem;
            margin-right: 0.4rem;
          }
        }
        .rotate {
          animation: rotation 2s infinite linear;
        }
      }
      span {
        font-size: 0.8rem;
        background-color: #258af4;
        color: #ffffff;
        padding: 0.3rem 0.8rem;
        border-radius: 1rem;
      }
      svg {
        stroke: #444444;
        width: 1.5rem;
        cursor: pointer;
      }
    }
  }
  .form-group {
    display: flex;
    justify-content: space-between;
    max-width: 40rem;
    margin-top: 1.5rem;
    & > div {
      position: relative;
      width: 48%;
      input,
      select {
        border: 1px solid #cccccc !important;
        margin-right: auto;
        width: 100%;
        height: 2.5rem;
        padding: 0.3rem 0.8rem;
        font-size: 0.9rem;
        outline: unset;
      }
      select {
        text-transform: capitalize;
        appearance: none;
        -webkit-appearance: none;
      }
      svg {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        fill: #bbbbbb;
        margin-right: 0.4rem;
        width: 1.1rem;
        cursor: pointer;
      }
    }
  }
  .main {
    .products-container {
      padding: 1.5rem 0;
      width: 65%;
      transition: all 0.3s ease 0s;
      .products {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
        gap: 1.2rem;
        .product,
        .product-loading {
          position: relative;
          display: flex;
          flex-direction: column;
          padding: 1rem;
          width: 10rem;
          height: 12rem;
          background-color: #ffffff;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          margin: auto;
          cursor: pointer;
          &-image {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 6rem;
            overflow: hidden;
            span {
              text-transform: uppercase;
              font-size: 2rem;
              font-weight: 600;
              color: rgb(37, 138, 244, 0.4);
            }
            img {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          &-name {
            margin-top: 6rem;
            h3 {
              text-transform: capitalize;
              font-size: 0.8rem;
            }
          }
          & > span {
            align-self: flex-start;
            background-color: #258af4;
            color: #ffffff;
            margin-top: auto;
            font-size: 0.7rem;
            line-height: 1;
            padding: 0.3rem;
            border-radius: 0.5rem;
          }
        }
        .product-quantity {
          &::before {
            position: absolute;
            top: 0;
            right: 0;
            height: 1.5rem;
            width: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #258af4;
            color: #ffffff;
            font-size: 10px;
            font-weight: 500;
            border-radius: 50%;
            margin: 0.5rem;
            z-index: 1;
            overflow: hidden;
            content: attr(data-quantity);
          }
        }
        .low-quantity {
          &::before {
            background-color: #f45e25;
          }
          .product-image {
            span {
              color: rgba(244, 134, 37, 0.4);
            }
          }
          & > span {
            background-color: #f45e25;
          }
        }
        .product-loading {
          &::before {
            display: none;
          }
          .product-image,
          h3,
          span {
            background-size: 200% 100%;
            background-image: linear-gradient(
              90deg,
              #ececec 0px,
              #ddd 40px,
              #ececec 80px
            );
            animation: shine 1s linear infinite;
          }
          h3 {
            margin: 0;
            height: 1.5rem;
            background-image: linear-gradient(
              90deg,
              #ececec 0px,
              #ddd 40px,
              #ececec 80px
            );
          }
          span {
            height: 1rem;
            width: 5rem;
          }
        }
      }
      .search {
        display: flex;
        flex-wrap: wrap;
        & > div {
          margin: 0 !important;
        }
      }
    }
    .banner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      bottom: 0;
      right: 0;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: #258af4;
      margin: 1rem;
      cursor: pointer;
      svg {
        width: 1.5rem;
        stroke: #ffffff;
      }
    }
    .cart {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 30%;
      min-width: 20rem;
      max-width: 30rem;
      box-shadow: rgb(0 0 0 / 25%) 0px 2px 20px 1px;
      z-index: 1240;
      .header {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        width: 100%;
        height: 2.5rem;
        padding-inline: 1rem;
        background-color: #258af4;
        color: #ffffff;
        & > svg {
          flex-shrink: 0;
          width: 1rem;
          height: 1rem;
          stroke: #ffffff;
          cursor: pointer;
        }
        & > p {
          font-size: 0.9rem;
          margin-left: auto;
        }
      }
      .items {
        .order-details {
          display: flex;
          align-items: center;
          font-size: 0.8rem;
          padding: 0.3rem 1rem;
          background-color: #dddddd;
          p {
            white-space: nowrap;
            span {
              font-weight: 500;
            }
          }
        }

        .main {
          height: 45vh;
          overflow-y: auto;
          background-color: #ffffff;
          border-bottom: 1px solid #cccccc;
          &::-webkit-scrollbar {
            width: 0.2rem;
          }
          &::-webkit-scrollbar-track {
            background-color: #c4c4c4;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #258af4;
          }
          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0.6rem 0.9rem;
            transition: all 0.3s;
            cursor: pointer;
            &:hover {
              background-color: rgba(0, 39, 255, 0.09);
              .controls {
                input {
                  background-color: white;
                }
              }
            }
            .description {
              h1 {
                text-transform: capitalize;
                font-size: 0.8rem;
              }
              p {
                display: inline-block;
                font-weight: 500;
                font-size: 0.8rem;
                span {
                  font-weight: 400;
                }
              }
              .qty {
                white-space: nowrap;
                width: 3rem;
                margin-right: 1rem;
              }
            }
            .controls {
              display: flex;
              gap: 0.3rem;
              input {
                background-color: #eeeeee;
                padding-inline: 0.5rem;
                width: 2.7rem;
                font-size: 0.8rem;
                transition: all 0.3s;
              }
              svg {
                width: 1.6rem;
                stroke-width: 1.3;
                cursor: pointer;
              }
            }
          }
        }
        .actions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          row-gap: 1rem;
          padding: 1rem;
          background-color: #ffffff;
          button {
            width: 48%;
            font-size: 0.8rem;
            padding: 0.4rem 0;
            color: #258af4;
            border: 1px solid #258af4;
            background-color: transparent;
            cursor: pointer;
            &:nth-child(1) {
              color: #ffffff;
              background-color: #258af4;
            }
            &:nth-child(4) {
              color: #f45e25;
              border: 1px solid #f45e25;
            }
          }
        }
      }
    }
  }
  .scan-mode {
    position: fixed;
    top: 6.5rem;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    padding: 1rem;
    z-index: 1239;
    height: calc(100% - 6.5rem);
    & > div {
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 70%;
        height: 95%;
        & > svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 1.5rem;
          height: 1.5rem;
          margin: 1rem 0;
          fill: #bbbbbb;
          cursor: pointer;
        }
        .ping {
          position: absolute;
          height: 0px;
          width: 0px;
          background-color: rgba(37, 137, 244, 0.187);
          border-radius: 50%;
          animation: ping 1.5s ease-in-out infinite;
        }
        input {
          position: absolute;
          top: 40%;
          opacity: 0;
        }
        h1 {
          color: var(--accent-color);
          font-size: 5rem;
          text-align: center;
        }
      }
    }
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes ping {
  0% {
    border: 0px solid transparent;
  }
  50% {
    border: 10rem solid rgba(37, 137, 244, 0.187);
  }
  100% {
    border: 0px solid transparent;
  }
}

@media screen and (max-width: 835px) {
  .container {
    header {
      & > div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
        button {
          &:first-child {
            width: 100%;
          }
          &:not(:first-child) {
            margin: 0;
            height: 2.4rem;
            outline: 1px solid #ccc;
          }
        }
      }
    }
    .main {
      .products-container {
        width: 70%;
      }
      .cart {
        .items {
          .actions {
            row-gap: 0.7rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 590px) {
  .container {
    .main {
      .products-container {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .container {
    padding: 1rem;
    header {
      & > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }
    }
    .form-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      margin-top: 0.5rem;
      & > div {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 525px) {
  .container {
    .main {
      .products-container {
        .products {
          grid-template-columns: 1fr 1fr;
          .product,
          .product-loading {
            width: 100%;
            height: 14rem;
            &-image {
              height: 8rem;
            }
            &-name {
              margin-top: 8rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .main {
      .products-container {
        .products {
          grid-template-columns: 1fr 1fr;
          .product,
          .product-loading {
            height: 12rem;
            &-image {
              height: 6rem;
            }
            &-name {
              margin-top: 6rem;
            }
          }
        }
      }
      .cart {
        min-width: 16rem;
        .header {
          padding-inline: 0.5rem;
        }
        .items {
          .order-details {
            padding: 0.3rem 0.7rem;
          }
          .actions {
            padding: 0.5rem;
          }
        }
      }
    }
  }
}
