.container {
  display: flex;
  height: 100vh;
  & > a {
    position: fixed;
    top: 0;
    left: 0;
    margin: 2rem 5%;
    z-index: 3;
    img {
      width: 6rem;
    }
  }
  form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline: 5%;
    width: 50%;
    height: 100vh;
    .indicator {
      display: flex;
      gap: 2rem;
      position: absolute;
      top: 10%;
      right: 10%;
      span {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: #767676;
        color: var(--text-white);
        line-height: 1;
        font-size: 1.2rem;
        cursor: default;
        &:not(:last-child) {
          &::before {
            position: absolute;
            top: 50%;
            left: 100%;
            transform: translateY(-50%);
            width: 200%;
            height: 0.1rem;
            background-color: #3d3d3d;
            content: "";
          }
        }
      }
      .active {
        background-color: var(--accent-color);
      }
    }
    & > h1 {
      font-size: 1.8rem;
    }
    & > p {
      font-size: 1rem;
      margin-bottom: 0.8rem;
    }
    fieldset {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 1.2rem;
      }
      label {
        width: 10rem;
        flex-shrink: 0;
        span {
          color: red;
        }
      }
      & > input {
        height: 2.5rem;
        width: 100%;
        padding: 0.3rem 0.7rem;
        background-color: transparent;
        border: 1px solid #cccccc !important;
        color: #333333;
        &:not([type="email"]) {
          text-transform: capitalize;
        }
        &:focus-visible {
          outline: 1px solid var(--accent-color) !important;
        }
      }
    }
    button {
      width: 100%;
    }
    & > div {
      margin-top: 0.5rem;
      p {
        text-align: center;
      }
    }
  }
  & > div {
    width: 50%;
    height: 100vh;
  }
}

@media screen and (max-width: 1045px) {
  .container {
    & > a {
      margin-inline: 20%;
    }
    & > form {
      width: 100%;
      padding-inline: 20%;
      .indicator {
        right: 20%;
      }
    }
    & > div {
      display: none;
    }
  }
}

@media screen and (max-width: 720px) {
  .container {
    & > a {
      margin-inline: 5%;
    }
    & > form {
      padding-inline: 5%;
      .indicator {
        right: 5%;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    & > form {
      fieldset {
        label {
          font-size: 0.9rem;
        }
      }
    }
  }
}
