.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  p {
    font-size: 1rem;
    margin: 0;
  }
}
