.modal {
  form {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 2rem;
    fieldset {
      display: flex;
      gap: 0.5rem;
      label {
        font-size: 0.9rem;
        text-transform: capitalize;
      }
      input {
        cursor: pointer;
      }
      select {
        padding: 0.2rem 0.8rem;
        border: 1px solid #cccccc;
      }
    }
  }
  .payment-options {
    margin: 2rem;
    h3 {
      font-size: 0.9rem;
    }
    table {
      font-size: 0.9rem;
      border-collapse: collapse;
      width: 100%;
      opacity: 1;
      transition: all 0.4s;
      background-color: var(--bg-primary);
      tr {
        th,
        td {
          &:not(:first-child) {
            text-align: center;
          }
        }
      }
      thead {
        tr {
          th {
            font-weight: 400;
          }
        }
      }
      tbody {
        border-top: 1px solid #000000;
        tr {
          td {
            text-transform: capitalize;
          }
        }
      }
      tfoot {
        border-top: 1px solid #000000;
        border-bottom: 1px solid #000000;
        font-weight: 500;
      }
    }
  }
  .footer {
    display: flex;
    padding: 0.8rem;
    border-top: 1px solid #cccccc;
    gap: 1rem;
    button,
    & > span {
      font-size: 0.9rem;
      padding: 0.2rem 0.5rem;
      cursor: pointer;
    }
    & > span {
      margin-left: auto;
      border: 1px solid #cccccc;
      background-color: transparent;
    }
    button {
      background-color: #258af4;
      border: 1px solid #258af4;
      color: #ffffff;
      transition: all 0.4s;
      width: 9rem;
      span {
        margin: auto;
        vertical-align: middle;
        transition: all 0.4s;
      }
    }
  }
}
